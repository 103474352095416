import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { SolicitudesCreditosService } from '../../services/solicitudes-creditos.service';
import { SolicitudCreditoClienteInterface, SolicitudCreditoInterface } from '../../interfaces/solicitud-credito';


@Component({
    selector: 'app-detalle-solicitudes',
    templateUrl: './detalle-solicitudes.component.html',
    styleUrls: ['./detalle-solicitudes.component.scss']
})
export class DetalleSolicitudesComponent implements OnInit {
    // id de la solicitud que llega por parametro
    @Input() idsolicitud: any;
    @Output() onGetSolicitud: EventEmitter<any> = new EventEmitter<any>();

    //
    solicitud: SolicitudCreditoInterface = {};
    clienteSolicitud: SolicitudCreditoClienteInterface = {};

    constructor(
        private router: Router,
        private routes: ActivatedRoute,
        private _fuseConfigService: FuseConfigService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _solicitudesCreditosService: SolicitudesCreditosService
    ) {
    }

    public async ngOnInit() {
        this._fuseSplashScreenService.show();
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                style: 'vertical-layout-1',
                width: 'fullwidth',
                navbar: {
                    background: 'mat-fuse-dark-700-bg',
                    folded: false,
                    hidden: false,
                    position: 'left',
                    variant: 'vertical-style-2'
                },
                toolbar: {
                    background: 'mat-white-500-bg',
                    hidden: false,
                    position: 'below-static'
                },
                footer: {
                    background: 'mat-fuse-dark-900-bg',
                    hidden: false,
                    position: 'below-fixed'
                },
                sidepanel: {
                    hidden: true,
                    position: 'right'
                }
            }
        };

        let result = await this._solicitudesCreditosService.getSolicitud(this.idsolicitud)
        console.log("Result de la solicitud: ", result)
        
        if (!result) {
            this._fuseSplashScreenService.hide();
            
            return;
        }
        this.onGetSolicitud.emit(result)
        this.solicitud = result.Request;
        console.log('Solicitud: ', this.solicitud)
        this.clienteSolicitud = result.Client;
        console.log('clienteSolicitud: ', this.clienteSolicitud)
        this._fuseSplashScreenService.hide();
    }

    async aceptarSolicitud() {
        let response = await this._solicitudesCreditosService.cambioStatus(this.idsolicitud, 'aceptada')
        this.router.navigate(['app', 'listado-solicitudes']);
    }

    rechazarSolicitud() {
        this.router.navigate(['app', 'listado-solicitudes']);
    }

}
