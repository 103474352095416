import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientesService } from '../../services/clientes.service';
import { ProductosService } from '../../services/productos.service';
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-detalle-cliente2',
    templateUrl: './detalle-cliente2.component.html',
    styleUrls: ['./detalle-cliente2.component.scss']
})
export class DetalleCliente2Component implements OnInit {
    // ID del cliente
    @Input() idcliente: any;
    @Output() closed = new EventEmitter<any>();
    private usuarioCarga: string;
    data_cliente: any;
    currentDate = new Date();
    meses = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ];

    listadoProductos: any[] = [];

    form_evaluation: FormGroup = new FormGroup({ //EXTENDS TO EVALUATIONRESULTS
        date: new FormControl(formatDate(this.currentDate, 'yyyy-MM-dd', 'en-US')),
        time: new FormControl(formatDate(this.currentDate, 'hh:mm:ss', 'en-US')),
        user_process: new FormControl(0),
        last_evaluation: new FormControl(true),
        evaluation_year: new FormControl(formatDate(this.currentDate, 'yyyy', 'en-US')),
        evaluation_month: new FormControl(formatDate(this.currentDate, 'MM', 'en-US')),
        interest_rate: new FormControl(0.0),
        opening_fee: new FormControl(''),
        status: new FormControl(true),
        percent_revenue: new FormControl(0.0),
        moratorium_interest: new FormControl(''),
        taxes: new FormControl(0.0),
        cat: new FormControl(''),
        type: new FormControl('default'),
        annual_rate: new FormControl(''),
        pre_authorized_amount: new FormControl(),
        credit_products_id: new FormControl(null),
        minimum_term: new FormControl(''),
        maximum_term: new FormControl(''),
        min_percent_revenue: new FormControl(''),
        max_percent_revenue: new FormControl(''),
        client_id: new FormControl(''),
        max_offer: new FormControl('')
    });

    form_requests: FormGroup = new FormGroup({ //EXTENDS TO REQUESTS
        clabe_account_asigned: new FormControl(0),
        request_id: new FormControl()
    });

    form_detalle2: FormGroup = new FormGroup({
        id: new FormControl(''),
        user_load: new FormControl(''),
        date: new FormControl(''),
        time: new FormControl(''),
        category: new FormControl('default'),
        score: new FormControl(0),
        created_by: new FormControl('admin'),
        modified_by: new FormControl('admin'),
        sales: new FormArray([]),
        EvaluationResults: this.form_evaluation,
        Requests: this.form_requests
    });

    constructor(
        public snackBar: MatSnackBar,
        private _fuseConfigService: FuseConfigService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private route: ActivatedRoute,
        private _clienteService: ClientesService,
        private _productosService: ProductosService,
        private router: Router
    ) {
        this._fuseSplashScreenService.show();
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                style: 'vertical-layout-1',
                width: 'fullwidth',
                navbar: {
                    background: 'mat-fuse-dark-700-bg',
                    folded: false,
                    hidden: false,
                    position: 'left',
                    variant: 'vertical-style-2'
                },
                toolbar: {
                    background: 'mat-white-500-bg',
                    hidden: false,
                    position: 'below-static'
                },
                footer: {
                    background: 'mat-fuse-dark-900-bg',
                    hidden: false,
                    position: 'below-fixed'
                },
                sidepanel: {
                    hidden: true,
                    position: 'right'
                }
            }
        };

        let initialDate = new Date();
        initialDate.setDate(1);

        let currentDate = new Date();
        currentDate.setDate(1);

        initialDate.setMonth(initialDate.getMonth() - 12);

        while (currentDate > initialDate) {
            let monthOption = new Date(initialDate);

            (this.form_detalle2.get('sales') as FormArray).push(
                new FormGroup({
                    id: new FormControl(
                        initialDate.getMonth() + 1 + ' ' + initialDate.getFullYear()
                    ),
                    nombre_mes: new FormControl(
                        this.meses[initialDate.getMonth()] + ' ' + initialDate.getFullYear()
                    ),
                    date: new FormControl(`${monthOption.getFullYear()}-${('0' + (monthOption.getMonth() + 1)).slice(-2)}-01`),
                    fees: new FormControl(0, [Validators.required]),
                    modified_by: new FormControl('default')
                })
            );

            initialDate.setMonth(initialDate.getMonth() + 1);
        }

        this._clienteService.getProductos().subscribe(res => {

            this.listadoProductos = res.rows;
        });
        this.usuarioCarga = JSON.parse(localStorage.getItem("profile")).id;
    }

    ngOnInit() {
        console.log("entro en ngOninit")
        this.getDetalleCliente(this.idcliente);
    }

    getDetalleCliente(idcliente) {
        this._clienteService.getDetalleCliente(idcliente).then(response => {
            console.log("Response: ", response)
            this.data_cliente = response.client;
            let re = response.request;
            this._fuseSplashScreenService.hide();
            this.form_detalle2.get('id').setValue(this.data_cliente.id);
            let evaluation_result = response.evaluation_result[0] || {};
            this.form_requests.get('clabe_account_asigned').setValue(re.clabe_account_asigned || '');
            this.form_requests.get('request_id').setValue(re.id);
            this.form_evaluation.get('max_offer').setValue(evaluation_result.max_offer || 0);
            this.form_evaluation.get('client_id').setValue(this.data_cliente.id);
            this.form_evaluation.get('annual_rate').setValue(evaluation_result.annual_rate);
            this.form_evaluation.get('opening_fee').setValue(evaluation_result.opening_fee);
            this.form_evaluation.get('moratorium_interest').setValue(evaluation_result.moratorium_interest);
            this.form_evaluation.get('cat').setValue(evaluation_result.cat);
            this.form_evaluation.get('min_percent_revenue').setValue(evaluation_result.min_percent_revenue);
            this.form_evaluation.get('max_percent_revenue').setValue(evaluation_result.max_percent_revenue);
            this.form_evaluation.get('minimum_term').setValue(evaluation_result.minimum_term);
            this.form_evaluation.get('maximum_term').setValue(evaluation_result.maximum_term);
            this.form_evaluation.get('interest_rate').setValue(evaluation_result.interest_rate);
            this.form_evaluation.get('credit_products_id').setValue(evaluation_result.credit_products_id)
            this.form_evaluation.get('taxes').setValue(evaluation_result.taxes)
            this.form_evaluation.get('pre_authorized_amount').setValue(evaluation_result.pre_authorized_amount)

            if (response.sales) {
                (this.form_detalle2.get('sales') as FormArray)
                    .controls.forEach((data: FormGroup) => {
                        let dt = data
                            .get('id')
                            .value.toString()
                            .split(' ', 2);
                        let result = response.sales.filter(obj => {
                            return obj.sales_period_by_year == dt[1] && obj.sales_period_by_month == dt[0];
                        });
                        if (result.length > 0) {
                            data.get('fees').setValue(
                                result[0].total_sales || 0
                            );
                        }
                    });
            }
        });
    }

    controlMaxLength(item: string) {
        return item.toString().length > 18;
    }

    controlValue(item: string) {
        return item ? false : true;
    }

    async updateRequest() {
        //Aqui se anidaran todos los formGroup para mandarlos
        this._fuseSplashScreenService.show();
        // Validate all required data
        if (!this.form_detalle2 || !this.form_detalle2.get("EvaluationResults").get('credit_products_id').value) {
            // Missing required fields, abort!
            this.showNotification("El campo Producto es requerido");
            this._fuseSplashScreenService.hide();
            return;
        } else if (!this.form_detalle2.get('Requests').get('clabe_account_asigned').value) {
            // Missing required fields, abort!
            this.showNotification("El campo cuenta CLABE asignada es requerido");
            this._fuseSplashScreenService.hide();
            return;
        }

        var request = this.form_detalle2.getRawValue();
        for (const sale in request.sales) {
            delete request.sales[sale]['id']
            delete request.sales[sale]['nombre_mes']
        }
        request.id = request.id;
        request.user_load = this.usuarioCarga;
        request.date = formatDate(this.currentDate, 'yyyy-MM-dd', 'en-US');
        request.time = formatDate(this.currentDate, 'hh:mm:ss', 'en-US');
        console.log("Esto es request: ", request)

        let response = await this._clienteService.cargaOferta(request);

        if (!response.status) {
            this.showNotification(response.error.errorMessage);
            this.showNotification(response.error);
            this.closed.emit("closed");
            //return;
        }
        console.log("Esto es from_detalle2: ", this.form_detalle2)
        this._fuseSplashScreenService.hide();
        this.closed.emit(true);  
    }

    close() {
        this.closed.emit("closed");
    }

    showNotification(message) {
        this.snackBar.open(message, "", {
            duration: 4000,
        });
    }

    obtenerDetalleProducto(producto) {
        this._productosService.getDetalleProducto(producto).subscribe(response => {
            this.form_evaluation.get('annual_rate').setValue(response.annual_interest_rate); //LISTO
            this.form_evaluation.get('opening_fee').setValue(response.opening_fee); //LISTO
            this.form_evaluation.get('moratorium_interest').setValue(response.annual_moratorium_interest_rate); //LISTO
            this.form_evaluation.get('cat').setValue(response.cat_without_tax); //LISTO
            this.form_evaluation.get('min_percent_revenue').setValue(response.min_retention_percentage_app); //LISTO
            this.form_evaluation.get('max_percent_revenue').setValue(response.max_retention_percentage_app);
            this.form_evaluation.get('minimum_term').setValue(response.contract_term);
            this.form_evaluation.get('maximum_term').setValue(response.complementary_term);
            this.form_evaluation.get('interest_rate').setValue(response.annual_interest_rate)
            this.form_evaluation.get('taxes').setValue(response.tax)
            this.form_evaluation.get('pre_authorized_amount').setValue(this.form_evaluation.get('max_offer').value)
        });

    }
}
