import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material'
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { ClientesService } from '../../services/clientes.service';
import { CuentasVirtualesService } from '../../services/cuentas-virtuales.service';
import { SummaryResolver } from '@angular/compiler';

@Component({
	selector: 'app-detalle-cliente-general',
	templateUrl: './detalle-cliente-general.component.html',
	styleUrls: ['./detalle-cliente-general.component.scss']
})
export class DetalleClienteGeneralComponent implements OnInit {
	// ID del cliente
	@Input() idcliente: any;
	@Input() vista: string;
	// bandera para saber si tenemos una cuenta virtual o no
	hasCuentaVirtual: boolean;
	// datos del cliente para editar
	cliente = {
		poolCliente: {
			amazon_id: '',
			calle: '',
			razon_social: '',
			ciudad: '',
			codigo_postal: '',
			colonia: '',
			correo: '',
			estado: '',
			cuenta_beneficiario: '',
			homoclave: '',
			fecha_registro: '',
			cuenta_virtual: {
				id: '',
				cuenta: ''
			},
			id: '',
			nombre: '',
			numero_ext: '',
			numero_int: '',
			pais: '',
			status: ''
		}
	};
	data_cliente: any;
	nationality: any;
	domicilio: any;
	platformData: any;
	requests: any;
	pldMatrix: any;
	pldColumns = ['question', 'description', 'value'];

	// arrays para los select
	status_clientes = [];
	cuentas_virtuales_libres = [];
	constructor(
		public snackBar: MatSnackBar,
		private _fuseConfigService: FuseConfigService,
		private _fuseSplashScreenService: FuseSplashScreenService,
		private route: ActivatedRoute,
		private _clienteService: ClientesService,
		private _cuentasVirtuales: CuentasVirtualesService,
		private router: Router,
	) {

	}

	public async ngOnInit() {
		this.hasCuentaVirtual = false;
		this._fuseSplashScreenService.show();
		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				style: 'vertical-layout-1',
				width: 'fullwidth',
				navbar: {
					background: 'mat-fuse-dark-700-bg',
					folded: false,
					hidden: false,
					position: 'left',
					variant: 'vertical-style-2'
				},
				toolbar: {
					background: 'mat-white-500-bg',
					hidden: false,
					position: 'below-static'
				},
				footer: {
					background: 'mat-fuse-dark-900-bg',
					hidden: false,
					position: 'below-fixed'
				},
				sidepanel: {
					hidden: true,
					position: 'right'
				}
			}
		};
		await this.getDetalleCliente(this.idcliente);
		this._fuseSplashScreenService.hide();
	}

	private async getDetalleCliente(idcliente) {
		let response = await this._clienteService.getDetalleCliente(idcliente)

		this.data_cliente = response.client;
		this.nationality = response.nationality;
		this.domicilio = response.address;
		this.platformData = response.platform_data;
		this.requests = response.request;
		this.pldMatrix = response.client_pld_risk_matrix;
	}


	guardarCliente(form) {
		const cliente = {
			'id': this.idcliente,
			'razon_social': form.value.razon_social,
			'calle': form.value.calle,
			'numero_ext': form.value.numero_ext,
			'numero_int': form.value.numero_int,
			'colonia': form.value.colonia,
			'ciudad': form.value.ciudad,
			'estado': form.value.estado,
			'codigo_postal': form.value.codigo_postal,
			'pais': form.value.pais,
			'status': form.value.status,
			'cuenta_beneficiario': form.value.cuenta_beneficiario,
			'homoclave': form.value.homoclave
		};
		let cliente_cuenta = {};
		if (this.hasCuentaVirtual) {
			this.editarUsuario(cliente).then(() => {
				this.showNotification('Registro editado correctamente');
			}).catch(() => {
				this.showNotification('Ocurrió un error al editar el registro');
			});
		} else {
			cliente_cuenta = {
				'cliente_id': this.idcliente,
				'cuenta_id': form.value.cuenta_virtual
			};
			this.asignarCuentaVirtual(cliente_cuenta).then(() => {
				this.editarUsuario(cliente).then(() => {
					this.showNotification('Registro editado correctamente');
				}).catch(() => {
					this.showNotification('Ocurrió un error al editar el registro');
				})
			}).catch(() => {
				this.showNotification('Ocurrió un error al editar el registro');
			});
		}
	}

	editarUsuario(cliente): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this._clienteService.editPoolCliente(cliente).toPromise().then((success) => {
				if (success['CodeStatus'] === 200) {
					resolve(true);
				} else {
					reject(false);
				}
			}).catch((error) => {
				reject(false);
			});
		});
	}

	asignarCuentaVirtual(cliente_cuenta): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this._cuentasVirtuales.asignarCuentaVirtual(cliente_cuenta).toPromise().then((exito) => {
				if (exito['CodeStatus'] === 200) {
					resolve(true);
				} else {
					reject(false);
				}
			}).catch((error) => {
				reject(false);
			});
		});
	}

	showNotification(message) {
		this.snackBar.open(message, '', {
			duration: 2000
		});
	}
}
