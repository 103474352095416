import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCardModule,
    MatSnackBarModule,
	MatListModule,
	MatTableModule
} from '@angular/material';
import { AuthGuard } from '../../guards/auth.guard';
import { PipesModule } from '../../pipes/pipes.module';
import {DetalleClienteGeneralComponent} from './detalle-cliente-general.component';

const routes: Routes = [
    {
        path: 'detalleClienteGeneral/:idcliente',
        component: DetalleClienteGeneralComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        DetalleClienteGeneralComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        FuseSharedModule,
        PipesModule,

        // material
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        MatSnackBarModule,
		MatListModule,
		MatTableModule
    ],
    exports     : [
        DetalleClienteGeneralComponent
    ]
})

export class DetalleClienteGeneralModule {}