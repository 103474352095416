import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCardModule,
    MatSnackBarModule,
    MatListModule
} from '@angular/material';
import { AuthGuard } from '../../guards/auth.guard';
import { PipesModule } from '../../pipes/pipes.module';
import {DetalleClienteDocsComponent} from './detalle-cliente-docs.component';

/*const routes: Routes = [
    {
        path: 'detalleClienteDocs/:idcliente',
        component: DetalleClienteDocsComponent,
        canActivate: [AuthGuard]
    }
];*/

@NgModule({
    declarations: [
        DetalleClienteDocsComponent
    ],
    imports     : [
        // RouterModule.forChild(routes),

        FuseSharedModule,
        PipesModule,

        // material
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        MatSnackBarModule,
        MatListModule
    ],
    exports     : [
        DetalleClienteDocsComponent
    ]
})

export class DetalleClienteDocsModule {}