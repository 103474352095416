import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Aplicaciones',
        type: 'group',
        children: [
            {
                id: 'clientes',
                title: 'Clientes',
                type: 'collapsable',
                icon: 'person',
                children: [
                    {
                        id: 'listado_clientes',
                        title: 'Revisión Inicial',
                        type: 'item',
                        icon: 'person',
                        url: 'app/listado-clientes'
                    },
                    {
                        id: 'listado_solicitudes_credito',
                        title: 'Revisión de Solicitudes',
                        type: 'item',
                        icon: 'list_alt',
                        url: 'app/listado-solicitudes'
                    },
                    {
                        id: 'historial_crediticio',
                        title: 'Historial Crediticio',
                        type: 'item',
                        icon: 'list_alt',
                        url: 'clientes/historial-crediticio'
                    },
                    {
                        id: 'contratos',
                        title: 'Contratos',
                        type: 'item',
                        icon: 'list_alt',
                        url: 'clientes/contratos'
                    }
                ]
            },
            {
                id: 'tesoreria',
                title: 'Tesorería',
                type: 'collapsable',
                icon: 'person',
                children: [
                    {
                        id: 'dispersion',
                        title: 'Dispersión',
                        type: 'item',
                        icon: 'person',
                        url: 'app/dispersion-reporte'
                    },
                    {
                        id: 'carga_pagos',
                        title: 'Cargar pagos',
                        type: 'item',
                        icon: 'add',
                        url: 'app/carga-pagos-layout'
                    },
                    {
                        id: 'carga-depositos',
                        title: 'Carga Depositos Exitosos',
                        type: 'item',
                        icon: 'add',
                        url: 'app/carga-depositos'
                    },
                ]
            },
            {
                id: 'score',
                title: 'Score',
                type: 'collapsable',
                icon: 'person',
                children: [
                    {
                        id: 'score1',
                        title: 'Score',
                        type: 'item',
                        icon: 'person',
                        url: 'app/score-grid'
                    },
                ]
            },
            {
                id: 'clip',
                title: 'CLIP',
                type: 'collapsable',
                icon: 'person',
                children: [
                    {
                        id: 'carga-elegibilidad',
                        title: 'Carga Elegibilidad',
                        type: 'item',
                        icon: 'add',
                        url: 'app/carga-elegibilidad'
                    },
                    {
                        id: 'ofertas',
                        title: 'Ofertas',
                        type: 'item',
                        icon: 'list_alt',
                        url: 'app/ofertas'
                    },
                    {
                        id: 'recepsion_pagos\n',
                        title: 'recepción de pagos\n',
                        type: 'item',
                        icon: 'list_alt',
                        url: 'app/resepcion-pagos'
                    },
                    {
                        id: 'sales',
                        title: 'Sales',
                        type: 'item',
                        icon: 'list_alt',
                        url: 'app/clip/sales'
                    },
                    {
                        id: 'clipReports',
                        title: 'Reportes',
                        type: 'collapsable',
                        icon: 'list_alt',
                        children: [
                            {
                                id: 'CloseOpsReport',
                                title: 'Cierre Operaciones',
                                type: 'item',
                                icon: 'list_alt',
                                url: 'app/clip/CloseOpReport'
                            },
                            {
                                id: 'OpsReport',
                                title: 'Report de Operaciones',
                                type: 'item',
                                icon: 'list_alt',
                                url: 'app/clip/OpsReport'
                            }
                        ]
                    }
                ]
            }
        ]
    }
];
