import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {
	MatButtonModule,
	MatIconModule,
	MatFormFieldModule,
	MatInputModule,
	MatPaginatorModule,
	MatTableModule,
	MatProgressSpinnerModule,
	MatDialogModule,
	MatDatepickerModule,
	MatSelectModule,
	MatCardModule,
	MatSnackBarModule,
	MatMenuModule,
	MatTooltipModule
} from '@angular/material';

import { MatGridListModule } from '@angular/material/grid-list';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { FormsModule } from '@angular/forms'
import { CustomErrorHandler } from './customclases/custom-error-handler';
import { Interceptor } from './customclases/interceptor';
import { HistorialCrediticioComponent } from './main/historial-crediticio/historial-crediticio.component';
import { ContratosComponent } from './main/contratos/contratos.component';
import { DialogClientesSolicitudesComponent } from './main/dialog-clientes-solicitudes/dialog-clientes-solicitudes.component';
import { DetalleCliente2Module } from './main/detalle-cliente2/detalle-cliente2.module';
import { DetalleClienteGeneralModule } from './main/detalle-cliente-general/detalle-cliente-general.module'
import { DetalleClienteDocsModule } from './main/detalle-cliente-docs/detalle-cliente-docs.module'
import { DetalleSolicitudesModule } from './main/detalle-solicitudes/detalle-solicitudes.module';
import { RoleGuard } from './guards/role.guard';

const appRoutes: Routes = [
	{
		path: '',
		loadChildren: 'app/auth/auth.module#AuthModule'
	},
	{
		path: 'app',
		loadChildren: 'app/main/main.module#MainModule'
	},
	{
		path: 'access_token',
		redirectTo: ''
	},
	{
		path: 'clientes/historial-crediticio',
		component: HistorialCrediticioComponent,
		canActivate: [RoleGuard]
	},
	{
		path: 'clientes/contratos',
		component: ContratosComponent,
		canActivate: [RoleGuard]
	},
	{
		path: '**',
		loadChildren: 'app/shared/shared.module#SharedModule'
	}
];

@NgModule({
	declarations: [
		AppComponent,
		HistorialCrediticioComponent,
		ContratosComponent,
		OnlyNumberDirective,
		DialogClientesSolicitudesComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,

		TranslateModule.forRoot(),

		// Material moment date module
		MatMomentDateModule,

		// Material
		MatGridListModule,
		MatButtonModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		MatPaginatorModule,
		MatTableModule,
		MatProgressSpinnerModule,
		MatDialogModule,
		MatDatepickerModule,
		MatSelectModule,
		MatCardModule,
		MatSnackBarModule,
		MatMenuModule,
		MatTooltipModule,

		DetalleCliente2Module,
		DetalleClienteGeneralModule,
		DetalleClienteDocsModule,
		DetalleSolicitudesModule,

		// Fuse modules
		FuseModule.forRoot(fuseConfig),
		FuseProgressBarModule,
		FuseSharedModule,
		FuseSidebarModule,
		FuseThemeOptionsModule,

		// App modules
		LayoutModule,
		RouterModule.forRoot(appRoutes, { useHash: true }),
		FormsModule
	],
	exports: [
		DialogClientesSolicitudesComponent
	],
	entryComponents: [
		DialogClientesSolicitudesComponent
	],
	bootstrap: [
		AppComponent
	],
	providers: [{ provide: ErrorHandler, useClass: CustomErrorHandler },
	{ provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }]
})
export class AppModule {
}
