import { Injectable } from '@angular/core';
import { Router, Event } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import * as auth0 from 'auth0-js';
import { Auth0Lock } from 'auth0-lock';
import { environment } from './../../environments/environment';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/filter';
import { tokenNotExpired } from 'angular2-jwt';
import { Observable } from 'rxjs';

(window as any).global = window;

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	// Store authentication data
	public expiresAt: number;
	public userProfile: any;
	public accessToken: string;
	public authenticated: boolean;

	// Configuration for Auth0
	options = {
		autoParseHash: false,
		sso: true,
		auth: {
			clientID: environment.auth.clientID,
			domain: environment.auth.auth0_domain,
			responseType: environment.auth.responseType,
			redirectUri: environment.auth.redirectUri,
			returnTo: environment.auth.returnTo,
			audience: environment.auth.audience,
			scope: environment.auth.scope
		}
	};

	lock = new Auth0Lock(environment.auth.clientID, environment.auth.auth0_domain, this.options);
	auth0 = new auth0.WebAuth(this.options.auth);

	constructor(private router: Router, private http: HttpClient) {
		this.lock.on('authenticated', authResult => {
			localStorage.setItem('id_token', authResult.idToken);

			this.lock.getUserInfo(authResult.accessToken, (error, profile) => {
				if (error) {
					throw new Error(error);
				}
				this.saveProfile(profile);
			});
		});

		this.lock.on('authorization_error', authResult => {
		});

		this.handleAuthenticationWithHash();
	}

	public handleAuthenticationWithHash(): void {
		this
			.router
			.events
			.filter((event: Event) => (/access_token|id_token|error/).test(event['url']))
			.subscribe((event: any) => {
				this.lock.resumeAuth(window.location.hash, (error, authResult) => {
					if (error || authResult == null) { return false; }
					this._setSession(authResult);
					this.setUser(authResult);
					console.log("Redireccionando a app/listado-clientes' CLienteSSSS!!!!")
					this.router.navigateByUrl('app/listado-clientes');
				});
			});
	}

	private setUser(authResult): void {
		const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
		localStorage.setItem('access_token', authResult.accessToken);
		localStorage.setItem('id_token', authResult.idToken);
		localStorage.setItem('expires_at', expiresAt);
	}

	private clearUser(): void {
		localStorage.removeItem('access_token');
		localStorage.removeItem('id_token');
		localStorage.removeItem('expires_at');
		localStorage.removeItem('profile');
	}

	login() {
		// Auth0 authorize request
		this.auth0.authorize();
	}

	handleLoginCallback() {
		// When Auth0 hash parsed, get profile
		this
			.router
			.events
			.filter((event: Event) => (/access_token|id_token|error/).test(event['url']))
			.subscribe((event: any) => {
				this.lock.resumeAuth(window.location.hash, (error, authResult) => {
					if (error || authResult == null) { return false; }
					this._setSession(authResult);
					this.setUser(authResult);
					this.router.navigateByUrl('app/dashboard');
				});
			});
	}

	private _setSession(authResult) {
		// Save authentication data and update login status subject
		this.expiresAt = authResult.expiresIn * 1000 + Date.now();
		this.accessToken = authResult.accessToken;
		this.authenticated = true;
		localStorage.setItem('userProfile', JSON.stringify(authResult));
	}

	logout() {
		// Log out of Auth0 session
		// Ensure that returnTo URL is specified in Auth0
		// Application settings for Allowed Logout URLs
		this.clearUser();
		this.lock.logout({
			returnTo: environment.auth.returnTo,
			clientID: environment.auth.clientID
		});
	}

	get isLoggedIn(): boolean {
		// Check if current date is before token
		// expiration and user is signed in locally
		const hasAccessToken = (localStorage.getItem('access_token')) ? true : false;

		return Date.now() < this.expiresAt && hasAccessToken;
		// return tokenNotExpired()
	}

	public getProfile(cb): void {
		const accessToken = localStorage.getItem('access_token');
		if (!accessToken) {
			throw new Error('Access Token must exist to fetch profile');
		}

		const self = this;
		this.auth0.client.userInfo(accessToken, (err, profile) => {
			if (profile) {
				self.userProfile = profile;
			}
			cb(err, profile);
		});
	}

	saveProfile(profile) {
		this.saveUser(profile).subscribe(response => {
			if (response.email) {
				localStorage.setItem('profile', JSON.stringify(response));
			} else {
				localStorage.setItem('profile', JSON.stringify(profile));
			}
		}, error1 => localStorage.setItem('profile', JSON.stringify(profile)));
	}
	saveUser(body): Observable<any> {
		delete body['https://alphacredit.mx/roles']
		delete body['https://alphacredit.mx/email']
		delete body['https://alphacredit.mx/family_name']
		delete body['https://alphacredit.mx/given_name']

		const url = `${environment.bontu.domain}/administradores/actualizar`;
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'X-Api-Key': environment.bontu.apiKey,
		});

		return this.http.post(url, body, { headers: headers });
	}
}
