import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {PipesModule} from '../../pipes/pipes.module';
import {MatButtonModule, MatIconModule, MatCardModule} from '@angular/material';
import {AuthGuard} from '../../guards/auth.guard';
import {DetalleSolicitudesComponent} from './detalle-solicitudes.component';

const routes: Routes = [
    {
        path: 'detalle-solicitudes/:idsolicitud',
        component: DetalleSolicitudesComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [DetalleSolicitudesComponent],
    imports: [
        RouterModule.forChild(routes),

        FuseSharedModule,

        PipesModule,

        MatButtonModule,
        MatIconModule,
        MatCardModule
    ],
    exports: [
        DetalleSolicitudesComponent
    ],
    providers: [],
})
export class DetalleSolicitudesModule { }
