import { Component, Inject, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatSnackBar,
	MatDialog
} from "@angular/material";
import { ClientesService } from "../../services/clientes.service";
import { SolicitudesCreditosService } from "../../services/solicitudes-creditos.service";
import { DialogRechazoSolicitudComponent } from "./dialog-rechazo-solicitud/dialog-rechazo-solicitud.component";
import { FormArray, FormGroup, Form } from "@angular/forms";
import { formatDate } from "@angular/common";
import { SlackService } from '../../services/slack.service';

@Component({
	selector: "app-dialog-clientes-solicitudes",
	templateUrl: "./dialog-clientes-solicitudes.component.html",
	styleUrls: ["./dialog-clientes-solicitudes.component.scss"]
})
export class DialogClientesSolicitudesComponent implements OnInit {
	@ViewChild('reasonInput') public reasonInput: ElementRef;
	estatus_clientes: any[] = []
	public matSelectDisabled: boolean = false;
	public reason: string;
	public new_status = {
		general_description: null,
		long_description: null
	}
	request: any
	description: string;
	vista: string;
	client_id: any;
	form_detalle: FormGroup;
	amazon_id: string;
	request_id: number;
	firebase_id: string;
	currentDate = new Date();
	meses = [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre"
	];
	private usuarioCarga: string;

	constructor(
		private dialogRef: MatDialogRef<DialogClientesSolicitudesComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private _clienteService: ClientesService,
		private _solicitudesCreditosService: SolicitudesCreditosService,
		public snackBar: MatSnackBar,
		public dialog: MatDialog,
		public _slackService: SlackService
	) {
		this.description = data.title;
		this.vista = data.vista;
		this.client_id = data.client_id;
		this.request_id = data.request_id;
		this.firebase_id = data.firebase_id;
		this.amazon_id = data.amazon_id ? data.amazon_id : "";
		this.usuarioCarga = JSON.parse(localStorage.getItem("profile")).id;
	}

	async ngOnInit() {
		console.log("client_id:", this.client_id)
		console.log("request_id:", this.request_id)
		console.log("firebase_id:", this.firebase_id)
	}

	close() {
		this.dialogRef.close();
	}

	getClosed(event) {
		if (event == 'closed') {
			this.dialogRef.close()
		} else {
			this.dialogRef.close(event);
		}
	}

	async aceptarSolicitud() {
		console.log("This.vista:", this.vista)
		if (this.vista === "solicitudes/aceptar") {
			let response = await this._solicitudesCreditosService
				.cambioStatus(this.client_id, this.usuarioCarga);
			this.dialogRef.close(true);
		}
	}

	async rechazarSolicitud() {
		const dialogRef = this.dialog.open(
			DialogRechazoSolicitudComponent,
			{
				width: "450px",
				data: { request_id: this.request_id, firebase_id: this.firebase_id, reason: "", estatus_clientes: this.estatus_clientes }
			}
		);

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.dialogRef.close(true);
				this.showNotification(`La solicitud ${this.request_id} ha sido rechazada`);
			}
		});
	}

	showNotification(message) {
		this.snackBar.open(message, "", {
			duration: 4000,
		});
	}

	borrarFiltros(reason) {
		this.reason = reason
	}

	async getStatus(request, rejected=true) {
		this.request = request
		this.matSelectDisabled = true;
		this.estatus_clientes = await this._clienteService.getStatus(this.request.Client.platform_catalog, rejected);
		this.matSelectDisabled = false;
	}
}
