import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientesService } from '../../../services/clientes.service';

export interface DialogData {
	reason: string;
	firebase_id: string;
	request_id: string;
	estatus_clientes: any[]
}

@Component({
	selector: 'app-dialog-rechazo-solicitud',
	templateUrl: './dialog-rechazo-solicitud.component.html',
	styleUrls: ['./dialog-rechazo-solicitud.component.scss']
})
export class DialogRechazoSolicitudComponent implements OnInit {

	public new_status = {
		general_description: null,
		long_description: null
	}
	public matInputDisabled: boolean = true;
	public matSpinnerDisabled: boolean = true;

	constructor(
		public dialogRef: MatDialogRef<DialogRechazoSolicitudComponent>,
		private _clienteService: ClientesService,
		@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

	ngOnInit() {
		console.log("Data:", this.data)
	}

	onChange(event) {
		this.data.reason = event.target.value;
		if (this.data.reason == '') {
			this.matInputDisabled = true;
		} else {
			this.matInputDisabled = false;
		}
	}

	back(data = undefined): void {
		this.dialogRef.close(data);
	}

	public async refusedRequest(): Promise<any> {
		this.matSpinnerDisabled = false;
		this.matInputDisabled = false;
		let response: any;
		response = await this._clienteService.refusedRequest(this.data.request_id, this.data.firebase_id, this.new_status.long_description, this.new_status.general_description)
		this.matSpinnerDisabled = true;
		this.back(response)
	}

}
