import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ceroGuion'
})
export class CeroGuionPipe implements PipeTransform {

  transform(value: any): any {
      return (value > 0) ? value : '-';
  }

}
