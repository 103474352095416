import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { debug } from 'util';

@Injectable({
	providedIn: 'root'
})
export class ClientesService {
	dato: any = {
		idclient: 'null',
		page: '0',
		pagerows: '5'
	};

	headers = new HttpHeaders({
		'Content-Type': 'Application/json'
	});


	constructor(public http: HttpClient) { }

	getClientes(): Observable<any> {
		const url = `${environment.bontu.domain}/listado-clientes`;
		return this.http.post(url, this.dato, { headers: this.headers });
	}

	getResultadosEvaluacion(idClientes): Observable<any> {
		const url = `${environment.bontu.domain}/get-offers`;
		return this.http.post(url, idClientes, { headers: this.headers });
	}

	getDataPage(paginacion): Observable<any> {
		const url = `${environment.bontu.domain}/listado-clientes`;
		return this.http.post(url, paginacion, { headers: this.headers });
	}

	getPoolClientes(page = 0, pagerows = 5): Observable<any> {
		const url = `${environment.bontu.domain}/listar-clientes?pag${page}&pagerow${pagerows}`;
		return this.http.get(url);
	}

	public getStatus(platform_id, rejected = null): Promise<any> {
		platform_id = platform_id ? platform_id : null;
		let query_parameters = `platform_id=${platform_id}`;
		if (rejected) {
			 query_parameters = `platform_id=${platform_id}&rejected=${rejected}`
		}
		const url = `${environment.bontu.domain}/flujo-piloto/catalogos/status?${query_parameters}`;

		return this.http.get(url).toPromise().catch((error) => {
			console.log("Error calling status catalogo:", error)
			return null;
		});
	}

	getPoolCliente(id): Observable<any> {
		const url = `${environment.bontu.domain}/`;
		return this.http.get(url);
	}

	editPoolCliente(body): Observable<any> {
		const url = environment.bontu.domain + '/?i${id}';
		return this.http.put(url, body);
	}
	/*Servicios para clientes y detalles de clientes*/
	getListadoClientesFilterByPage(data, page, pagerows): Promise<any> {
		const body = {
			'page': page,
			'page_rows': pagerows,
			'filters': data
		}

		return this.http.post(environment.bontu.domain + '/flujo-piloto/clientes/search/findBy', body, { headers: this.headers }).toPromise().catch((error) => {
			console.log("Error calling:", environment.bontu.domain + '/flujo-piloto/clientes/search/findBy', "Error message:", error)
			return null;
		});
	}

	public getDetalleCliente(idCliente): Promise<any> {
		return this.http.get(environment.bontu.domain + '/flujo-piloto/clientes/' + idCliente, { headers: this.headers }).toPromise();
	}
	
	cambioStatus(id, status): Observable<any> {
		return this.http.put(environment.bontu.domain + '/flujo-piloto/clientes/' + id + '/status', { status: status }, { headers: this.headers });
	}
	cargaOferta(detalle: any): Promise<any> {
		return this.http.post(environment.bontu.domain + '/flujo-piloto/carga-oferta', detalle, { headers: this.headers }).toPromise()
			.then(() => { return { status: true } })
			.catch((error) => {
				console.log("Error post cargaOferta:", error)
				return { status: false, error: error };
			})
	}
	refusedRequest(request_id, firebase_id, reason, new_status): Promise<any> {
		const body = {
			request_id: request_id ? request_id: null, 
			firebase_id: firebase_id ? firebase_id: null, 
			reason: reason ? reason: null,
			new_status: new_status ? new_status: null
		}
		console.log('body para rechazar solicitud: ', body)
		return null
		//return this.http.put(environment.bontu.domain + '/flujo-piloto/cliente/request/', body, { headers: this.headers }).toPromise().catch((error) => null);
	}
	saveOfertaClabe(id, body): Observable<any> {
		return this.http.post(environment.bontu.domain + '/flujo-piloto/clientes/' + id + '/informacion_extra', body, { headers: this.headers });
	}
	saveVentasMes(body): Observable<any> {
		return this.http.post(environment.bontu.domain + '/clientes/cargas/agregar', body, { headers: this.headers });
	}
	saveOtherRegisterUser(body): Observable<any> {

		return this.http.post(environment.bontu.domain + '/clientes/etl', body, { headers: this.headers });
	}
	getProductos(): Observable<any> {

		return this.http.post(environment.bontu.domain + '/productos-crediticios/listado', {}, { headers: this.headers });
	}

	public sendPrecargaImagen(body): Promise<any> {
		return this.http.post(`${environment.bontu.domain}/flujo-piloto/cliente/documentos/precargar`, body, { observe: 'response' }).toPromise();
	}

	public sendCargaImagen(url, body): Promise<any> {
		return this.http.post(url, body, { headers: null, observe: 'response' }).toPromise();
	}

	public sendKeyToStore(body): Promise<any> {
		return this.http.post(`${environment.bontu.domain}/flujo-piloto/cliente/documentos`, body, { observe: 'response' }).toPromise().catch(() => {
			return false;
		});
	}
}