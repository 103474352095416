import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SolicitudesCreditosService {

    headers = new HttpHeaders({
        'Content-Type': 'Application/json',
    });

    constructor(
        private http: HttpClient
    ) { }

    getSolicitudes(page, pageRows, status): Promise<any> {
        const body = {
            'idclient': 'null',
            'status': status,
            'page': page,
            'page_rows': pageRows,
        };

        return this.http.post(`${environment.bontu.domain}/solicitudes/listado`, body, { headers: this.headers }).toPromise().catch((error) => {
            console.log("Error al obtener solicitudes: ", error)
            return null
        });
    }

    public getSolicitud(id): Promise<any> {
        console.log("id que recibe getSolicitud: ", id)
        const body = {
            'request_id': id.toString()
        };

        return this.http.post(`${environment.bontu.domain}/solicitudes/detalle`, body, { headers: this.headers }).toPromise().catch(error => {
            return null;
        });
    }

    cambioStatus(id, usuariocarga): Promise<any> {
        const body = {
            'request_id': id,
            'administrator_id': usuariocarga,
        }
        return this.http.post(`${environment.bontu.domain}/administradores/dispersiones`, body, { headers: this.headers }).toPromise().catch((error) => {
            console.log("Error al realizar la dispersion: ", error)
            return null
        });
    }

    rechazarSolicitud(firebase_id, reason): Promise<any>{
        const body = {
            'firebase_id': firebase_id,
            'reason': reason
        }
        return this.http.put(`${environment.bontu.domain}/flujo-piloto/cliente/request`, body, { headers: this.headers }).toPromise().catch((error) => {
            console.log("Error al realizar la dispersion: ", error)
            return null
        });
        
    }

    private saveOferta(body): Observable<any> {
        return this.http.post(`${environment.bontu.domain}/solicitudes/detalle`, body, { headers: this.headers });
    }
}
