import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	fuseConfig: any;
	navigation: any;

	// Private
	private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     * @param auth
     * @param router
     */
	constructor(
		@Inject(DOCUMENT) private document: any,
		private _fuseConfigService: FuseConfigService,
		private _fuseNavigationService: FuseNavigationService,
		private _fuseSidebarService: FuseSidebarService,
		private _fuseSplashScreenService: FuseSplashScreenService,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private _translateService: TranslateService,
		private _platform: Platform,
		private router: Router,
		private auth: AuthService
	) {
		// Get default navigation
		this.navigation = navigation;

		// Register the navigation to the service
		this._fuseNavigationService.register('main', this.navigation);

		// Set the main navigation as our current navigation
		this._fuseNavigationService.setCurrentNavigation('main');

		// Add languages
		this._translateService.addLangs(['en', 'tr']);

		// Set the default language
		this._translateService.setDefaultLang('en');

		// Set the navigation translations
		this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

		// Use a language
		this._translateService.use('en');

		// Add is-mobile class to the body if the platform is mobile
		if (this._platform.ANDROID || this._platform.IOS) {
			this.document.body.classList.add('is-mobile');
		}

		// Set the private defaults
		this._unsubscribeAll = new Subject();

		// Handling HasURL and auth0
		auth.handleAuthenticationWithHash();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
	ngOnInit(): void {
		// Subscribe to config changes
		this._fuseConfigService.config
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config) => {
				this.fuseConfig = config;

				if (this.fuseConfig.layout.width === 'boxed') {
					this.document.body.classList.add('boxed');
				}
				else {
					this.document.body.classList.remove('boxed');
				}
			});

		let roles = JSON.parse(localStorage.getItem('userProfile')).idTokenPayload['https://alphacredit.mx/roles'];
		console.log("Roles::", roles)

		if(roles.indexOf("mx-bontu-admin") > -1){
			return;
		}
		else if (roles.indexOf("Tesoreria") > -1) {
			this._fuseNavigationService.removeNavigationItem('clientes')
			this._fuseNavigationService.removeNavigationItem('score')
			this._fuseNavigationService.removeNavigationItem('clip')
		} else{
			this._fuseNavigationService.removeNavigationItem('tesoreria')
		}
	}

    /**
     * On destroy
     */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
	toggleSidebarOpen(key): void {
		this._fuseSidebarService.getSidebar(key).toggleOpen();
	}
}
