import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RoleGuard implements CanActivate {
	constructor(
		private router: Router
	){}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		let roles = JSON.parse(localStorage.getItem('userProfile')).idTokenPayload['https://alphacredit.mx/roles'];
		console.log("Role Guard:", state.url)

		// if (roles.indexOf("Tesoreria") > -1 && state.url != '/app/dispersion-reporte' && '/app/carga-pagos-layout'){
		// 	console.log("Going to dispersion")
		// 	this.router.navigate(['app/dispersion-reporte'])
		// 	return false;
		// }else{
		// 	return true;
		// }
		return true;
	}
}
