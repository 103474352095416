import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment} from 'environments/environment';
declare const AWS: any;
@Injectable({
  providedIn: 'root'
})
export class CuentasVirtualesService {

    headers = new HttpHeaders({
    });

    constructor(private http: HttpClient) {}

    getCuentasVirtuales(status_id = 0 , page = 0, pagerows = 5): Observable<any> {
        return this.http.get(`${environment.bontu.domain}/cuentas-virtuales?estatus_i${status_id}&pag${page}&pagerow${pagerows}`, {headers: this.headers});
    }

    deleteCuentaVirtual(cuenta_id): Observable<any> {
        return this.http.post(`${environment.bontu.domain}/cuentas-virtuales/eliminar`, {cuenta_id: cuenta_id}, {headers: this.headers});
    }

    asignarCuentaVirtual(body): Observable<any> {
        return this.http.post(`${environment.bontu.domain}/cuentas-virtuales/asignar`, body, {headers: this.headers});
    }

    uploadFile (fileName, type, file): Promise<object> {
        let profileid = JSON.parse(localStorage.getItem('profile'));
        profileid = profileid.id;
        return new Promise ( (resolve, reject) => {
        });
    }

    sendFileToLambda(url_file, user_id): Promise<object> {
        return new Promise( (resolve, reject) => {
            this.http.post(`${environment.bontu.domain}/imorortacion-masiva`, {url: url_file, user_id: user_id}, {headers: this.headers}).subscribe( res => {
                resolve(res);
            }, error => {
                reject(error);
            });
        });
    }

    exportarCuentasVirtuales(estatus_id): Observable<any> {
        return this.http.post(`${environment.bontu.domain}/cuentas-virtuales/exportar`, {estatus_id: estatus_id}, {headers: this.headers});
    }

    detalleCargaCuentasVirtuales(foliocarga, page = 0, pagerows = 5): Observable<any> {
        const body = {
            folio_carga: foliocarga,
            page: page,
            pagerows: pagerows
        };
        return this.http.post(`${environment.bontu.domain}/logs-carga/detalle`, body, {headers: this.headers});
    }
}
