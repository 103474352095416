import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

declare const AWS: any;

@Injectable({
  providedIn: 'root'
})
export class ProductosService {
    dato: any = {
        idproducto: ''
    };

    comision: any = {
        id: ''
    };
    constructor( public http: HttpClient ) { }

    getProductos(body): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'Application/json'
        });
        return this.http.post(`${environment.bontu.domain}/obtenerlistadoproductoscrediticios`, body, {headers: headers});
    }

    getCatalogos(): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'Application/json'
        });
        return this.http.post(`${environment.bontu.domain}/obtener-catalogos`, '', {headers: headers});
    }

    getConfiguraciones(calculos): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'Application/json'
        });
        return this.http.post(`${environment.bontu.domain}/obtener-calculo`, calculos, {headers: headers});
    }

    guardarProducto(guardar): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'Application/json'
        });
        return this.http.post(`${environment.bontu.domain}/guardar-producto`, guardar, {headers: headers});
    }

    getProducto( idProducto): Observable<any> {
        this.dato.idproducto = idProducto;
        const headers = new HttpHeaders({
            'Content-Type': 'Application/json'
        });
        return this.http.post(`${environment.bontu.domain}/obtener-producto-crediticio`, this.dato, {headers: headers});
    }

    getDetalleProducto(idProducto): Observable<any>{
        const headers = new HttpHeaders({
            'Content-Type': 'Application/json'
        });
        return this.http.post(`${environment.bontu.domain}/productos-crediticios/obtener`, {idproducto: idProducto}, {headers: headers});
    }

    crearComisiones(comisiones): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'Application/json'
        });
        return this.http.post(`${environment.bontu.domain}/default/`, comisiones, {headers: headers});
    }

    modificarComisiones( datos ): Observable<any>{
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',

        });
        return this.http.put(`${environment.bontu.domain}/default/`, datos , {headers: headers});
    }

    modificarProducto(datos): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'Application/json',
        });
        return this.http.post(`${environment.bontu.domain}/actualizar-producto-crediticio`, datos , {headers: headers});
    }

    eliminarComision(datos): Observable<any> {
        this.comision.id = datos;
        const headers = new HttpHeaders({
            'Content-Type': 'Application/json',
        });
        return this.http.post(`${environment.bontu.domain}/delete-comision`, this.comision , {headers: headers});
    }

    calculosProductosCredito(productid, monto, porcentaje): Observable<any>{
        const body = {
            productoc_id: productid,
            monto_credito: monto,
            porcentaje_retencion: (porcentaje >= 1) ? (porcentaje / 100) : porcentaje
        };
        const headers = new HttpHeaders({
            'Content-Type': 'Application/json',
        });
        return this.http.post(`${environment.bontu.domain}/obtener-calculos-producto`, body, {headers: headers});
    }
}
