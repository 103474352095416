import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SlackService {

  url = "https://hooks.slack.com/services/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";

  constructor(public http: HttpClient) { }

  giphy = {
    baseURL: "https://api.giphy.com/v1/gifs/",
    apiKey: "0UTRbFtkMxAplrohufYco5IY74U8hOes",
    tag: "angry",
    type: "random",
    rating: "pg-13"
  };

  postSlack (msj: any): Observable<any> {
    const url = this.url;

    const headers = new HttpHeaders({
      'Content-Type': 'Application/json'
    });

    let text = this.formatMsj(msj);
    var options = {
      'method' : 'post',
      'payload' : JSON.stringify(text)
    };

    console.log("optio s:", options, url,)
    return this.http.post(url,options, {headers: headers});
  }

  getImage(_url:any): Observable<any> {
      const headers = new HttpHeaders({
      });
      return this.http.get(_url, {headers: headers});
  }

  formatMsj(msj){
    //  msj.replace('"',"").replace('.',"/n");
      let _image: any;
      var giphyURL = encodeURI( this.giphy.baseURL + this.giphy.type +"?api_key=" + this.giphy.apiKey +"&tag=" + this.giphy.tag +"&rating=" + this.giphy.rating);
      var searchImage = this.getImage(giphyURL).subscribe(val => _image = val);
      var image = _image;
      var error = {
        "blocks": [
          {
            "type": "section",
            "text": {
              "type": "mrkdwn",
              "text": "Error al ejecutar: \n "+ JSON.stringify(msj) +"\n:unamused: :angry:"
            }
          },
          {
            "type": "image",
            "title": {
              "type": "plain_text",
              "text": "Example Image",
              "emoji": true
            },
            "image_url": image,
            "alt_text": "Example Image"
          }]
      };
      return error;
  }

  //funcion obtener imagen

  //post url funcion
}
