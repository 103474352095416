import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material';
import { HttpClient } from '@angular/common/http'
import { environment } from 'environments/environment'

@Injectable({
	providedIn: 'root'
})
export class ClienteService {
	constructor(
		private http: HttpClient
	) { }

	public getClientes(pageIndex: number, pageSize: number = 10, filterNombre: string = null, filterRFC: string = null): Promise<any> {
		let url = `${environment.bontu.domain}/historialCrediticio?page-index=${pageIndex}&page-size=${pageSize}`
		url += `${filterNombre ? `&filter-nombre=${filterNombre}` : ''}`
		url += `${filterRFC ? `&filter-rfc=${filterRFC}` : ''}`

		return this.http.get(url).toPromise().catch(error => {
			console.log("Error calling historialCrediticio:", error)
			return null;
		})
	}

	public getContracts(pageIndex: number, pageSize: number = 10): Promise<any> {
		let url = `${environment.bontu.domain}/flujo-piloto/cliente/contracts?page=${pageIndex}&page_rows=${pageSize}`

		return this.http.get(url).toPromise().catch(error => {
			console.log("Error calling contracts:", error)
			return null;
		})
	}

	public SendContract(body): Promise<any> {
		let url = `${environment.bontu.domain}/solicitudes/SendEmailQR`

		return this.http.post(url, body).toPromise().catch(error => {
			console.log("Error calling contracts:", error)
			return null;
		})
	}

	public Unlock(body): Promise<any> {
		let url = `${environment.bontu.domain}/clickink/unlock`

		return this.http.post(url, body).toPromise().catch(error => {
			console.log("Error calling contracts:", error)
			return null;
		})
	}
}