import { NgModule } from '@angular/core';
import {BooleanPipe} from './boolean.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { CeroGuionPipe } from './cero-guion.pipe';
import { KeysPipe } from './keys.pipe';
import { MultiplyPipe } from './multiply.pipe';
import { ArraySumPipe } from './array-sum.pipe'

@NgModule({
    declarations : [BooleanPipe, CapitalizePipe, CeroGuionPipe, KeysPipe, MultiplyPipe, ArraySumPipe],
    exports      : [BooleanPipe, CapitalizePipe, CeroGuionPipe, KeysPipe, MultiplyPipe, ArraySumPipe]
})
export class PipesModule { }
