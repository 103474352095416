import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SlackService } from '../services/slack.service';
import { environment } from './../../environments/environment'
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(public _SlackService: SlackService, public router: Router){}

    intercept(request: HttpRequest<any>, next: HttpHandler){
        let access_token = localStorage.getItem('access_token')

        let customRequest = request.clone({})

        if (request.url.includes(environment.bontu.domain)) {
            customRequest= request.clone({
                setHeaders: {
                    "X-Api-Key": environment.bontu.apiKey,
                    "authorizationtoken": access_token,
                    "usertype": 'AUTH0'
                }
            })
        }else{
            customRequest = request.clone({})
        }
        return next.handle(customRequest).pipe(map((event: HttpEvent<any>) => {
            return event;
        }),
        catchError((error: HttpErrorResponse) => {

            // 1-. First check if the error is due to an invalid token, if that is the case
            // the user surely got a 401 or 403 error, in this cases, is not an error of the app
            // the user session just expired so don't log this to slack, instead logout the user
            console.log("Http error response:", error)
            if (request.url.includes(environment.bontu.domain)
                && (error.status == 401 || error.status == 403)) {
                this.router.navigate(['app/access-deny']);
                return throwError(error)
            }


            if(!customRequest.url.includes('slack')){
                this._SlackService.postSlack(customRequest)
            }

            return throwError(error)
        }))
    }
}