import { Component, OnInit } from '@angular/core';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { PageEvent } from '@angular/material';
import { ClienteService } from 'app/services/cliente.service';

interface Contracts {
	id: number;
	firebase_id: string;
	request_id: number;
	datef: Date;
	complete_name: string;
	email: string;
	administrator_app_description: string;
	las_sent: Date;
	las_unlock: Date;
	contract_url: string;
	fad_id: string;
}

@Component({
	selector: 'app-contratos',
	templateUrl: './contratos.component.html',
	styleUrls: ['./contratos.component.scss']
})
export class ContratosComponent implements OnInit {

	public contracts: Array<Contracts>;

	public pageEvent: PageEvent = {
		length: 0,
		pageIndex: 0,
		pageSize: 10,
		previousPageIndex: 0
	}

	public pageSizeOptions: number[] = [10, 20, 50];

	public matPaginatorDisabled = false;

	public ContractsColumnsToDisplay = ['firebaseId', 'nombre', 'correo', 'userPlatform', 'las_sent', 'las_unlock', 'unlock', 'send']

	constructor(
		private fuseConfigService: FuseConfigService,
		private fuseSplashScreenService: FuseSplashScreenService,
		private clienteService: ClienteService,
	) { }

	public async ngOnInit() {
		this.fuseSplashScreenService.show();

		this.fuseConfigService.config = {
			layout: {
				style: 'vertical-layout-1',
				width: 'fullwidth',
				navbar: {
					background: 'mat-fuse-dark-700-bg',
					folded: false,
					hidden: false,
					position: 'left',
					variant: 'vertical-style-2'
				},
				toolbar: {
					background: 'mat-white-500-bg',
					hidden: false,
					position: 'below-static'
				},
				footer: {
					background: 'mat-fuse-dark-900-bg',
					hidden: false,
					position: 'below-fixed'
				},
				sidepanel: {
					hidden: true,
					position: 'right'
				}
			}
		}

		await this.getContracts(this.pageEvent.pageIndex, this.pageEvent.pageSize)

		this.fuseSplashScreenService.hide();
	}

	public async getContracts(pageIndex: number, pageSize = 10) {
		if (this.matPaginatorDisabled) return;

		this.matPaginatorDisabled = true;
		let result = await this.clienteService.getContracts(pageIndex + 1, pageSize);

		this.contracts = result.rows;
		this.pageEvent.length = result.totalRows
		this.matPaginatorDisabled = false;
	}

	public async paginacion(pageEvent: PageEvent) {
		this.pageEvent = pageEvent;
		this.getContracts(this.pageEvent.pageIndex, this.pageEvent.pageSize)
	}

	public async sendEmail(contract: any) {
		if (this.matPaginatorDisabled) return;

		let body = {
			"userId": contract.id,
			"token": contract.fad_id,
			"solicitud_id": contract.firebase_id,
			"url": contract.contract_url,
			"email": contract.email,
			"signature_type": contract.signature_type
		}
		
		this.unlock(contract, false)

		let result = await this.clienteService.SendContract(body);

		if (result.statusCode === 200) {
			await this.getContracts(this.pageEvent.pageIndex, this.pageEvent.pageSize)
		}
	}

	public async unlock(contract: any, refresh: boolean = true) {
		if (this.matPaginatorDisabled) return;

		let body = {
			"document_id": contract.fad_id,
			"request_id": contract.request_id
		}

		let result = await this.clienteService.Unlock(body);

		if (result.statusCode === 200 && refresh) {
			await this.getContracts(this.pageEvent.pageIndex, this.pageEvent.pageSize)
		}
	}

}