import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'arraySum'
})
export class ArraySumPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		return value.reduce((acumulator, currentValue) => {
			console.log("Current Value:", currentValue[args])
			console.log("Acumulator:", acumulator)
			return currentValue[args] + acumulator;
		}, 0);
	}
}
