import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCardModule,
    MatSnackBarModule
} from '@angular/material';
import { AuthGuard } from '../../guards/auth.guard';
import { PipesModule } from '../../pipes/pipes.module';
import { DetalleCliente2Component } from './detalle-cliente2.component';
const routes: Routes = [
    {
        path: 'detalleCliente2/:idcliente',
        component: DetalleCliente2Component,
        canActivate: [AuthGuard]
    }
];


@NgModule({
    declarations: [
        DetalleCliente2Component
    ],
    imports     : [
        RouterModule.forChild(routes),

        FuseSharedModule,
        PipesModule,

        // material
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        MatSnackBarModule,
        FormsModule,
        CurrencyMaskModule,
    ],
    exports     : [
        DetalleCliente2Component
    ]
})
export class DetalleCliente2Module { }
