import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material'
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { ClientesService } from '../../services/clientes.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-detalle-cliente-docs',
    templateUrl: './detalle-cliente-docs.component.html',
    styleUrls: ['./detalle-cliente-docs.component.scss']
})
export class DetalleClienteDocsComponent implements OnInit {
    // ID del cliente
    @Input() idCliente: any;
    @Input() vista: string;
    idcliente: any;
    // bandera para saber si tenemos una cuenta virtual o no
    hasCuentaVirtual: boolean;
    // datos del cliente para editar
    cliente = {
        poolCliente: {
            amazon_id: '',
            calle: '',
            razon_social: '',
            ciudad: '',
            codigo_postal: '',
            colonia: '',
            correo: '',
            estado: '',
            cuenta_beneficiario: '',
            homoclave: '',
            fecha_registro: '',
            cuenta_virtual: {
                id: '',
                cuenta: ''
            },
            id: '',
            nombre: '',
            numero_ext: '',
            numero_int: '',
            pais: '',
            status: ''
        }
    };
    documents: any;
    client: any;
    platform: any;
    request: any;
    trustedUrl: SafeResourceUrl;
    url: any;
    doc_name: string;
    document_ext: string;
    show_document: boolean;
    upload_form: boolean;
    onLoading: boolean;
    doc_type_remedy: string;
    document_list = [];

    // arrays para los select
    status_clientes = [];
    cuentas_virtuales_libres = [];
    constructor(private sanitizer: DomSanitizer,
        public snackBar: MatSnackBar,
        private _fuseConfigService: FuseConfigService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _clienteService: ClientesService

    ) {
        this.hasCuentaVirtual = false;
        this._fuseSplashScreenService.show();
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                style: 'vertical-layout-1',
                width: 'fullwidth',
                navbar: {
                    background: 'mat-fuse-dark-700-bg',
                    folded: false,
                    hidden: false,
                    position: 'left',
                    variant: 'vertical-style-2'
                },
                toolbar: {
                    background: 'mat-white-500-bg',
                    hidden: false,
                    position: 'below-static'
                },
                footer: {
                    background: 'mat-fuse-dark-900-bg',
                    hidden: false,
                    position: 'below-fixed'
                },
                sidepanel: {
                    hidden: true,
                    position: 'right'
                }
            }
        };
    }

    ngOnInit() {
        this.getDetalleCliente(this.idCliente);
    }

    getDetalleCliente(idcliente) {
        this._clienteService.getDetalleCliente(idcliente).then(response => {
            this.client = response.client;
            this.documents = response.documents;
            this.platform = response.platform;
            this.request = response.request;
            this._fuseSplashScreenService.hide();
        });
    }

    viewDocument(url, doc_name) {
        
        this.show_document = true;
        this.upload_form = false;
        this.doc_name = doc_name;
        this.document_ext = doc_name.split('.').pop().toUpperCase();
        ['PNG', 'JPG', 'JPEG', 'BMP'].includes(this.document_ext) ?
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url) :
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`);
    }

    uploadDocumentForm(docType) {
        this.upload_form = true;
        this.show_document = false;
        this.doc_type_remedy = docType

    }


    hideDocument() {
        this.show_document = false;
    }

    hideuploadForm() {
        this.upload_form = false;
    }

    private async fileChange(event, type) {
        this.onLoading = true;
        let fileList: FileList = event.target.files;

        if (fileList.length > 0) {
            let file: File = fileList[0];
            const formData: FormData = new FormData();

            let preLoadpayload = {
                'user_platform': this.client.user_platform,
                'document': {
                    'name': file.name,
                    'type': type
                }
            }

            let result = await this._clienteService.sendPrecargaImagen(preLoadpayload)

            let bodyResult = result.body;
            formData.append('key', bodyResult.fields.key);
            formData.append('AWSAccessKeyId', bodyResult.fields.AWSAccessKeyId);
            formData.append('x-amz-security-token', bodyResult.fields['x-amz-security-token']);
            formData.append('policy', bodyResult.fields.policy);
            formData.append('signature', bodyResult.fields.signature);
            formData.append('file', file, file.name);

            //Resultado del API
            let result2 = await this._clienteService.sendCargaImagen(`${bodyResult.url}`, formData)
            if (result2.status && result2.status == 204) {
                preLoadpayload['document']['s3_key'] = bodyResult.fields.key

                let payload = {
                    'user_platform': this.client.user_platform,
                    'documents': [preLoadpayload['document']]
                }

                let result = await this._clienteService.sendKeyToStore(payload)

                if (result) {
                    this.getDetalleCliente(this.idCliente);
                }
            }
            this.onLoading = false;
            this.hideuploadForm();
        }
    }
}
