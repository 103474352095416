import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { PageEvent } from '@angular/material';
import { ClienteService } from 'app/services/cliente.service';
import { debounceTime, tap, filter, distinctUntilChanged } from 'rxjs/operators'
import { fromEvent, Observable, Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogClientesSolicitudesComponent } from '../dialog-clientes-solicitudes/dialog-clientes-solicitudes.component';

interface Cliente {
	idCliente: number;
	rfc: string;
	nombreORazonSocial: string;
	plataforma: string;
}

@Component({
	selector: 'app-historial-crediticio',
	templateUrl: './historial-crediticio.component.html',
	styleUrls: ['./historial-crediticio.component.scss']
})
export class HistorialCrediticioComponent implements OnInit {
	@ViewChild('nombreInput') private nombreInput: ElementRef;
	@ViewChild('rfcInput') private rfcInput: ElementRef;
	public nombreDebounce: Subscription;
	public rfcDebounce: Subscription;
	public nombre: string;
	public rfc: string;

	public clientes: Array<Cliente>;
	public columnsToDisplay = ['rfc', 'nombre', 'plataforma']
	public historialColumnsToDisplay = ['numeroDeCredito', 'estatus', 'fechaDeDispersion', 'monto', 'comisionPorDisposicion', 'tasaDeInteres', 'plazo', 'porcentajeDeRetencion', 'datosGenerales', 'expediente']

	public pageEvent: PageEvent = {
		length: 0,
		pageIndex: 0,
		pageSize: 10,
		previousPageIndex: 0
	}

	public pageSizeOptions: number[] = [10, 20, 50];

	public matPaginatorDisabled = false;

	constructor(
		private fuseConfigService: FuseConfigService,
		private fuseSplashScreenService: FuseSplashScreenService,
		private clienteService: ClienteService,
		private dialog: MatDialog
	) { }

	public async ngOnInit() {
		this.fuseSplashScreenService.show();

		this.fuseConfigService.config = {
			layout: {
				style: 'vertical-layout-1',
				width: 'fullwidth',
				navbar: {
					background: 'mat-fuse-dark-700-bg',
					folded: false,
					hidden: false,
					position: 'left',
					variant: 'vertical-style-2'
				},
				toolbar: {
					background: 'mat-white-500-bg',
					hidden: false,
					position: 'below-static'
				},
				footer: {
					background: 'mat-fuse-dark-900-bg',
					hidden: false,
					position: 'below-fixed'
				},
				sidepanel: {
					hidden: true,
					position: 'right'
				}
			}
		}

		// # Load clientes
		await this.loadClientes(this.pageEvent.pageIndex, this.pageEvent.pageSize)

		// Add debounce functionality to filters
		this.nombreDebounce = fromEvent(this.nombreInput.nativeElement, 'keyup').pipe(
			filter(Boolean),
			debounceTime(500),
			distinctUntilChanged(),
			tap(async (event: KeyboardEvent) => {
				this.pageEvent.pageIndex = 0;
				this.nombre = this.nombreInput.nativeElement.value;

				this.loadClientes(this.pageEvent.pageIndex, this.pageEvent.pageSize, this.nombre, this.rfc)
			})
		).subscribe()

		this.rfcDebounce = fromEvent(this.rfcInput.nativeElement, 'keyup').pipe(
			filter(Boolean),
			debounceTime(500),
			distinctUntilChanged(),
			tap(async (event: KeyboardEvent) => {
				this.pageEvent.pageIndex = 0;
				this.rfc = this.rfcInput.nativeElement.value;

				this.loadClientes(this.pageEvent.pageIndex, this.pageEvent.pageSize, this.nombre, this.rfc)
			})
		).subscribe()

		this.fuseSplashScreenService.hide();
	}

	public async loadClientes(pageIndex: number, pageSize = 10, nombre: string = null, rfc: string = null) {
		// Database pagination starts at index 1, and MatPaginator starts at index 0
		// Add 1 to the MatPaginator Index
		if (this.matPaginatorDisabled) return;

		this.matPaginatorDisabled = true;
		let result = await this.clienteService.getClientes(pageIndex + 1, pageSize, nombre, rfc);

		this.clientes = result.clientes;
		console.log("Clientes:", this.clientes)
		this.pageEvent.length = result.totalRows
		this.matPaginatorDisabled = false;
	}

	public async page(pageEvent: PageEvent) {
		this.pageEvent = pageEvent;
		this.loadClientes(this.pageEvent.pageIndex, this.pageEvent.pageSize, this.nombre, this.rfc)
	}

	public async borrarFiltros() {
		this.pageEvent.pageIndex = 0;
		this.nombre = undefined;
		this.rfc = undefined;
		this.loadClientes(this.pageEvent.pageIndex, this.pageEvent.pageSize)
	}

	public ngOnDestroy() {
		this.nombreDebounce.unsubscribe();
		this.rfcDebounce.unsubscribe();
	}

	public openDialog(idcliente, vista) {
		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = false;
		dialogConfig.autoFocus = true;
		dialogConfig.width = '70%';
		dialogConfig.height = 'auto';

		dialogConfig.data = {
			vista: vista,
			title: 'Listado Documentos',
			client_id: idcliente
		}

		const dialogRef = this.dialog.open(DialogClientesSolicitudesComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(response => {
		})
	}
}