// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---dev` replaces `environment.ts` with `environment.dev.ts`.
// The list of file replacements can be found in `angular.json`.

const redirect = 'https://dev-administrator.bontu-dev.io/#/callback';
const returnTo = 'https://dev-administrator.bontu-dev.io/';

const environment = {
    bontu: {
        domain: 'https://api.bontu-dev.io/dev',
        apiKey: 'JEZ9yY9Jkd6n2FBEquTdC536sXthBpxv39tyJJB4'
    },
    production: true,
    hmr       : false,
    auth: {
        clientID: 'ZhqKX155Bjg63XD0euCdQTy0DrTyL21j',
        auth0_domain: 'alcanza-dev.auth0.com', // e.g., you.auth0.com
        responseType: 'token id_token',
        audience: 'https://alcanza-dev.auth0.com/userinfo', // e.g., http://localhost:3001
        redirectUri: redirect,
        returnTo:  returnTo,
        scope: 'openid profile email'
    }
}
 export{environment};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.